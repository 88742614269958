import * as React from 'react';
import './styles.scss';
import { confirmAlert } from 'react-confirm-alert';

interface ATTRIBUTE {
  [key: string]: string;
}
interface Props {
  isPlaceholder: boolean;
  nft?: {
    tokenId: number;
    image: string;
    name: string;
    description: string;
    tokenLogo: string;
    attribute: ATTRIBUTE;
  };
}

const submit = (nftImg: string) => {
  confirmAlert({
    customUI: () => {
      return(
        <img
          className='fullImg'
          src={nftImg} 
          alt='img'/>
      );
    },
    overlayClassName: "confirm-alert-overlay"
  });
};

const WsaCard: React.FC<Props> = ({ nft, isPlaceholder }) => {
  return (
    <div className={'wsaCard'}>
      {isPlaceholder || !nft ? (
        <div className={'nftItem'}>
          <img src={`${process.env.PUBLIC_URL}/static/images/mosaic.jpg`} alt={'img'} />
          <div className={'bio'}>XX - XX - XXX</div>
        </div>
      ) : (
        <div className={'nftItem'} key={`tokenId${nft.tokenId}`}>
          <img 
            src={nft.image} 
            alt={'img'}
            onClick={() => {
              submit(nft.image);
            }}
          />
          <div className={'bio'}>
            <div className={'idAndTheme'}>
              <div>#{nft.tokenId}</div>
              <div>{nft.attribute.Theme}</div>
            </div>
            <div className={'nameAndDate'}>
              <div>
                <img src={nft.tokenLogo} alt={nft.attribute.Symbol} />
                {nft.attribute.Symbol}
              </div>
              <div>{nft.attribute.Date}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WsaCard;
