import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import './FAQ.scss';
import { LanguageContext } from '../../contexts/LanguageContext';

const { Panel } = Collapse;
const FAQ: React.FC<any> = ({ faqRef }) => {
  const languageFile = useContext(LanguageContext);
  const [activePanel, setActivePanel] = useState<string[]>();

  function getAnswer(a: any) {
    if (a.type === 'route') {
      return (
        <Link to={a.route}>
          <p>{a.text}</p>
        </Link>
      );
    }

    return <p>{a.text}</p>;
  }
  return (
    <div className={'faq itemEntre'} ref={faqRef}>
      <div className={'subtitleWrapper'}>
        <img
          className={'numberImage leftImage horizontalImage'}
          src={`${process.env.PUBLIC_URL}/static/images/09.png`}
          alt="07"
        />
        <h1 className="subtitle themeYellow leftTitle">FAQ</h1>
      </div>
      <div className={'contentWrapper'}>
        <Collapse
          onChange={(active: any) => {
            setActivePanel(active);
          }}
        >
          {languageFile.faq.map((item: any, index: number) => (
            <Panel
              header={
                <h3>
                  {item.q}
                  {activePanel?.includes(index.toString()) ? (
                    <img src={`${process.env.PUBLIC_URL}/static/images/minus.png`} alt="close" />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="open" />
                  )}
                </h3>
              }
              key={index}
              showArrow={false}
            >
              {getAnswer(item.a)}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default FAQ;
