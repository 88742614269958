import * as React from 'react';
import { Link } from 'react-router-dom';
// import { message } from 'antd';
import { reduceAddress, CURRENT_SUPPORT_CHAIN_ID } from '../../utils';
import { pathList } from './config';
import './styles.scss';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
// import detectEthereumProvider from '@metamask/detect-provider';

interface Props {
  address?: string;
  faqRef: any;
  readWeb3Instance: any;
  chainId: number;
}
const Header: React.FC<Props> = ({ address, faqRef, readWeb3Instance, chainId }) => {
  const [menuTransform, setMenuTransform] = useState<string>('translateX(-100%)');
  const [showBoxShadow, setShowBoxShadow] = useState<boolean>(false);
  const hash = window.location.hash;
  const selected = pathList.find((path) => (path === 'home' ? hash === '#/' : hash.includes(path)));
  const languageFile = useContext(LanguageContext);
  function openMenu() {
    setMenuTransform('translateX(0%)');
  }

  function closeMenu() {
    setMenuTransform('translateX(-100%)');
  }
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 150 && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (window.pageYOffset <= 150 && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };
  });

  return (
    <div>
      {/* <a href={"https://opensea.io/collection/wsa"} target={'_blank'} rel="noreferrer">
        <div className='openSeaContainer'>
          <div className='message'>
            <p>To get your WSA, check out the collection on OpenSea</p>
            <img src={`${process.env.PUBLIC_URL}/static/images/white_right_arrow.png`} alt='arrow'/>
          </div>
          <div className='close'>
            <img src={`${process.env.PUBLIC_URL}/static/images/close-bold.png`} alt='close'/>
          </div>
        </div>
      </a> */}
      <div className={'header'} style={{ boxShadow: showBoxShadow ? '0 5px 5px #8D8D8D80' : 'none' }}>
        <div className="mainContainer">
          <img
            src={`${process.env.PUBLIC_URL}/static/images/menu.png`}
            alt="menu"
            className={'menuIcon'}
            onClick={() => {
              openMenu();
            }}
          />
          <Link to={'/'}>
            <div className={'logo'}>
              <div>
                <img src={`${process.env.PUBLIC_URL}/static/images/logo.png`} alt="logo" />
              </div>
            </div>
          </Link>

          <div className={'tabs'}>
            {pathList.map((path) => (
              <Link key={path} to={`/${path === 'home' ? '' : path}`}>
                <div
                  className={selected === path ? 'active' : ''}
                  onClick={() => {
                    if (path === 'faq') {
                      setTimeout(() => {
                        if (faqRef) {
                          faqRef.current.scrollIntoView();
                        }
                      }, 10);
                    }
                  }}
                >
                  {languageFile.header[path]}
                </div>
              </Link>
            ))}
          </div>

          <div className={'mobileTabsWrapper'} style={{ transform: menuTransform }}>
            <div className={'mobileTabs'}>
              <img src={`${process.env.PUBLIC_URL}/static/images/logo.png`} alt="logo" />
              {pathList.map((path: string) => (
                <Link key={path} to={`/${path === 'home' ? '' : path}`}>
                  <div
                    className={selected === path ? 'active' : ''}
                    onClick={() => {
                      closeMenu();
                      if (path === 'faq') {
                        setTimeout(() => {
                          if (faqRef) {
                            faqRef.current.scrollIntoView();
                          }
                        }, 10);
                      }
                    }}
                  >
                    {languageFile.header[path]}
                  </div>
                </Link>
              ))}
            </div>
            <div
              className={'shadow'}
              onClick={() => {
                closeMenu();
              }}
            />
          </div>

          <div>
            {/*<div className={'wallet'}>{languageFile.header.prepareForLaunch}</div>*/}
            <div
              className={'wallet'}
              onClick={() => {
                if (window.ethereum) {
                  readWeb3Instance();
                } else {
                  window.open(
                    'https://metamask.app.link/dapp/wallstreet.art',
                    '_blank', // <- This is what makes it open in a new window.
                  );
                }
              }}
            >
              {chainId > 0 && chainId !== CURRENT_SUPPORT_CHAIN_ID ? 'Switch to Mainnet' : reduceAddress(address)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
