import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Row, Col, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import { BrowserRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Utils, numeral, multicall, checkTime, date2CountdownString, CURRENT_SUPPORT_CHAIN_ID } from '../../utils';
// import { default as WSA } from '../../utils/abis/WSA.json';
import { default as WsaSeller } from '../../utils/abis/WsaSeller.json';
import Countdown from '../../components/Countdown';
// import axios from 'axios';
import './styles.scss';
import './modal.scss';
import { LanguageContext } from '../../contexts/LanguageContext';
import ReactTooltip from 'react-tooltip';
import { default as ADDRESSES } from '../../utils/ADDRESSES.json';
import ScrollContainer from 'react-indiana-drag-scroll';
// import { render } from '@testing-library/react';
// import Roadmap from '../Home/Roadmap';

interface Props {
  address?: string;
  readWeb3Instance: any;
  chainId: number;
}
// interface ATTRIBUTE {
//   [key: string]: string;
// }
//
// interface NFT {
//   tokenId: number;
//   image: string;
//   name: string;
//   description: string;
//   attribute: ATTRIBUTE;
// }

const Mint: React.FC<Props> = ({ address, readWeb3Instance, chainId }) => {
  // const [supplyAmount, setSupplyAmount] = useState<number>(200);
  const [remaining, setRemaining] = useState<number>(200);
  const [price, setPrice] = useState<number>(0.08);
  const [ethBalance, setEthBalance] = useState<number>(0);
  const [ethMintAmount, setEthMintAmount] = useState<number>(0);
  const [wstMintAmount, setWstMintAmount] = useState<number>(0);
  const [ethLimitPerAddress, setEthLimitPerAddress] = useState<number>(10);
  const [wstLimitPerAddress, setWstLimitPerAddress] = useState<number>(10);
  const [mintPending, setMintPending] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [gasCost, setGasCost] = useState<number>(0);
  const [countdownString, setCountdownString] = useState<string>();
  const [allowance, setAllowance] = useState<number>(0);
  const [wscBalance, setWscBalance] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>('ETH');

  const [inputAmount, setInputAmount] = useState<number>(0.0);
  const wscDropAmount: number = 1000;

  const languageFile = useContext(LanguageContext);
  const supplyAmount = 200;

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal">
            <img src={`${process.env.PUBLIC_URL}/static/images/success.png`} alt="success" />
            <h1>Mint Success!</h1>
            <p>Do you want go to wallet page to check your WSA?</p>
            <div
              className="confirmButton"
              onClick={() => {
                window.location.href = '/#/wallet';
                onClose();
              }}
            >
              Go to Wallet
            </div>
          </div>
        );
      },
      overlayClassName: 'overlay-custom-class-name',
    });
  };

  useEffect(() => {
    setTotalPrice(inputAmount * price);
  }, [inputAmount, price]);

  const getContractData = useCallback(async () => {
    if (Utils.wsa) {
      try {
        const addr = address ? address : '0x0000000000000000000000000000000000000000';
        const balance = address ? Utils.web3.utils.fromWei(await Utils.web3.eth.getBalance(addr)) : 0;
        setEthBalance(balance);

        const myWscBalance = Utils.web3.utils.fromWei(await Utils.wsc.methods.balanceOf(addr).call());
        setWscBalance(myWscBalance);

        const totalSupply = await Utils.wsa.methods.totalSupply().call();
        const calls = [];
        calls.push([Utils.seller1._address, 'PRICE_ETH', []]); //0
        calls.push([Utils.seller1._address, 'PRICE_WST', []]); // 1
        calls.push([Utils.seller1._address, 'START_TIME', []]); // 2
        calls.push([Utils.seller1._address, 'ETH_LIMIT_PER_ADDRESS', []]); // 3
        calls.push([Utils.seller1._address, 'SUPPLY_AMOUNT', []]); // 4
        calls.push([Utils.seller1._address, 'MAX_SUPPLY', []]); // 5
        calls.push([Utils.seller1._address, 'getMintByEthRecords', [addr]]); // 6
        calls.push([Utils.seller1._address, 'getMintByWstRecords', [addr]]); // 7
        calls.push([Utils.seller1._address, 'WST_LIMIT_PER_ADDRESS', []]); // 8

        const contractRes = await multicall(WsaSeller.abi, calls);
        let basePrice;
        if (paymentMethod === 'ETH') basePrice = Utils.web3.utils.fromWei(contractRes[0][0].toString());
        else basePrice = Utils.web3.utils.fromWei(contractRes[1][0].toString());
        setPrice(basePrice);
        const startTime = Number(contractRes[2][0].toString());
        setCountdownString(date2CountdownString(new Date(startTime * 1000)));
        setEthLimitPerAddress(Number(contractRes[3][0].toString()));
        setWstLimitPerAddress(Number(contractRes[8][0].toString()));
        // const roundSupply = Number(contractRes[4][0].toString());
        // setSupplyAmount(roundSupply);

        const _maxSupply = Number(contractRes[5][0].toString());

        const remainingSupply = _maxSupply * 1 - totalSupply;
        setRemaining(remainingSupply);

        const ethMinted = Number(contractRes[6][0].toString());
        setEthMintAmount(ethMinted);

        const wstMinted = Number(contractRes[7][0].toString());
        setWstMintAmount(wstMinted);

        const getAllowance = await Utils.wsc.methods.allowance(addr, ADDRESSES.seller1).call();
        setAllowance(Utils.web3.utils.fromWei(getAllowance));

      } catch (e) {
        console.log(e);
      }
    } else {
      setTimeout(() => {
        getContractData().then();
      }, 1000);
      return;
    }
  }, [address, paymentMethod]);

  useEffect(() => {
    let timer: any;

    getContractData().then();

    timer = setTimeout(() => {
      getContractData().then();
    }, 15000);

    return () => clearTimeout(timer);
  }, [address, paymentMethod, getContractData]);

  function buy() {
    if (!Utils.seller1 || !address) {
      if (window.ethereum) {
        readWeb3Instance();
      } else {
        window.open(
          'https://metamask.app.link/dapp/wallstreet.art',
          '_blank', // <- This is what makes it open in a new window.
        );
      }
      return;
    }
    if (chainId !== CURRENT_SUPPORT_CHAIN_ID) {
      message.success('Please switch to mainnet!', 3);
      return;
    }

    if (allowance <= 0 && paymentMethod === 'WST') {
      Utils.wsc.methods
        .approve(ADDRESSES.seller1, '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')
        .send({ from: address })
        .on('transactionHash', (hash: any) => {
          console.log(hash);
          setMintPending(true);
          message.success(languageFile.message.approveSent, 3);
        })
        .then((res: any) => {
          console.log(res);
          setMintPending(false);
          getContractData().then();
          message.success(languageFile.message.approveCompleted, 3);
        })
        .catch((err: any) => {
          console.log(err);
          setMintPending(false);
          message.error(err.message, 3);
        });

      return;
    }

    const mintCost = inputAmount * Number(price);
    if (mintCost > (paymentMethod === 'ETH' ? ethBalance : wscBalance)) {
      console.log('not enough eth');
      message.error(languageFile.message.insufficientBalance, 3);
      return;
    }

    if (
      inputAmount === 0 ||
      !Utils.seller1 ||
      mintPending ||
      !checkTime(countdownString)
    ) {
      return;
    }

    if((paymentMethod === "ETH" && ethMintAmount >= ethLimitPerAddress) || 
        (paymentMethod === "WST" && wstMintAmount >= wstLimitPerAddress))
    {
      message.error(languageFile.message.youHaveReachedMintQuota, 3);
      return;
    }

    try {
      if (paymentMethod === 'ETH') {
        Utils.seller1.methods
          .mintByETH(inputAmount)
          .send({
            from: address,
            value: Utils.web3.utils.toWei(mintCost.toString()),
          })
          .on('transactionHash', (hash: string) => {
            console.log(hash);
            setMintPending(true);
            message.success(languageFile.message.transactionSent, 3);
          })
          .then((res: any) => {
            console.log(res);
            setMintPending(false);
            setInputAmount(0);
            getContractData().then();
            message.success(languageFile.message.transactionCompleted, 3);
            submit();
            //add confirm dialogue
          })
          .catch((err: any) => {
            console.log(err);
            setMintPending(false);
            message.error(err.message, 3);
          });
      } else {
        if (allowance <= 0) return;
        Utils.seller1.methods
          .mintByWST(inputAmount)
          .send({ from: address })
          .on('transactionHash', (hash: string) => {
            console.log(hash);
            setMintPending(true);
            message.success(languageFile.message.transactionSent, 3);
          })
          .then((res: any) => {
            console.log(res);
            setMintPending(false);
            setInputAmount(0);
            message.success(languageFile.message.transactionCompleted, 3);
            getContractData().then();
            submit();
          })
          .catch((err: any) => {
            console.log(err);
            setMintPending(false);
            message.error(err.message, 3);
          });
      }
    } catch (e) {
      console.log(e);
      message.error(`Transaction failed with :${e}`, 3);
    }
  }

  function getMintButton() {
    if (remaining === 0) {
      return languageFile.mint.soldOut;
    } else if (allowance <= 0 && paymentMethod === 'WST') {
      return 'Approve';
    } else if (
      (paymentMethod === 'ETH' && ethMintAmount >= ethLimitPerAddress) ||
      (paymentMethod === 'WST' && wstMintAmount >= wstLimitPerAddress)
    ) {
      return languageFile.mint.minted;
    } else {
      if (checkTime(countdownString)) {
        return languageFile.mint.mint;
      } else {
        return <Countdown date={countdownString} />;
      }
    }
  }

  async function calcGasCost(amount: number) {
    if (amount === 0) {
      setGasCost(0);
    } else if (Utils.web3) {
      const gasPrice = await Utils.web3.eth.getGasPrice();
      const gasCostInWei = 378119 + (amount - 1) * 261581;
      const gasCostEstimate = Utils.web3.utils.fromWei((gasPrice * gasCostInWei).toString());
      setGasCost(Math.floor(gasCostEstimate * 1000) / 1000);
    }
  }

  return (
    <div className={'mint mainContainer'}>
      <div className={'contentWrapper'}>
        <div className={'bannerWrapper'}>
          <Row justify="space-between" align={'middle'} gutter={{ xs: 0, sm: 0, md: 32 }}>
            <Col xs={24} sm={24} md={12}>
              <div className={'slogan'}>
                <h1>WSA NFT</h1>
                <h2>{languageFile.home.slogan}</h2>
                <p style={{ marginBottom: 10 }}>{languageFile.mint.intro1}</p>
                <p>{languageFile.mint.intro2}</p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <ScrollContainer 
                className={'exampleImages'}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/homeIllustration1.png`}
                  alt="img1"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/example_night.png`}
                  alt="img2"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/example_glitch.png`}
                  alt="img4"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/example_sunset.png`}
                  alt="img3"
                />
              </ScrollContainer>
            </Col>
          </Row>
        </div>
        <Row justify="center" align={'top'} gutter={{ xs: 0, sm: 0, md: 16 }}>
          <Col xs={12} sm={12} md={6}>
            <div className={'remain'}>
              <div className={'name'}>{languageFile.mint.remaining}</div>
              <div className={'value'}>
                <h1>
                  {remaining}
                  <span>/{supplyAmount}</span>
                </h1>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <div className={'price'}>
              <div className={'name'}>{languageFile.mint.mintPrice}</div>
              <div className={'value'}>
                <h1>
                  {price}
                  <span>{paymentMethod}</span>
                </h1>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <div className={'price'}>
              <div className={'name'}>{languageFile.mint.tokenDrop}</div>
              <div className={'value'}>
                <h1>
                  {wscDropAmount}
                  <span>WST</span>
                </h1>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <div className={'price'}>
              <div className={'name'}>{languageFile.mint.capPerWallet}</div>
              <div className={'value'}>
                <h1>
                  {paymentMethod === 'ETH' ? ethLimitPerAddress - ethMintAmount : wstLimitPerAddress - wstMintAmount}
                  <span>WSA</span>
                </h1>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={24}>
            <div className="mintBy">Mint By</div>
            {paymentMethod === 'ETH' ? (
              <div className="switchButton">
                <div className="stakeTab" onClick={() => setPaymentMethod('ETH')}>
                  ETH
                </div>
                <div className="UnstakeTab" onClick={() => setPaymentMethod('WST')}>
                  WST
                </div>
              </div>
            ) : (
              <div className="switchButton">
                <div className="stakeTabUnstake" onClick={() => setPaymentMethod('ETH')}>
                  ETH
                </div>
                <div className="UnstakeTabUnstake" onClick={() => setPaymentMethod('WST')}>
                  WST
                </div>
              </div>
            )}
          </Col>
          <Col xs={24} sm={24} md={6}>
            <div className={'mintButton'}>
              <div className={'inputWrap'}>
                <img
                  className={'img2'}
                  src={`${process.env.PUBLIC_URL}/static/images/minus.png`}
                  alt="minus"
                  onClick={() => {
                    if (
                      (paymentMethod === 'ETH' && ethMintAmount === ethLimitPerAddress) ||
                      (paymentMethod === 'WST' && wstMintAmount === wstLimitPerAddress)
                    ) {
                      message.info(languageFile.message.youHaveReachedMintQuota);
                    }
                    const input = Math.max(inputAmount - 1, 0);
                    setInputAmount(input);

                    calcGasCost(input);
                  }}
                />
                <div className={'inputAmount'}>{inputAmount}</div>
                <img
                  className={'img2'}
                  src={`${process.env.PUBLIC_URL}/static/images/plus.png`}
                  alt="plus"
                  onClick={() => {
                    if (
                      (paymentMethod === 'ETH' && ethMintAmount === ethLimitPerAddress) ||
                      (paymentMethod === 'WST' && wstMintAmount === wstLimitPerAddress)
                    ) {
                      message.info(languageFile.message.youHaveReachedMintQuota);
                    }
                    const input = Math.min(
                      inputAmount + 1,
                      paymentMethod === 'ETH' ? ethLimitPerAddress - ethMintAmount : wstLimitPerAddress - wstMintAmount,
                      remaining,
                    );
                    setInputAmount(input);
                    calcGasCost(input);
                  }}
                />
              </div>

              <div className="totalPrice">
                <h1>Total Cost</h1>
                <div className="cost">
                  <h2>
                    {totalPrice}
                    <span> {paymentMethod} + gas </span>
                  </h2>
                  <img
                    src={`${process.env.PUBLIC_URL}/static/images/questionCircle.png`}
                    alt="question"
                    data-tip
                    data-for="gasTip"
                  />
                </div>
                <ReactTooltip id="gasTip" place="top" effect="solid">
                  Estimate gas cost: {gasCost} ETH
                </ReactTooltip>
              </div>

              <div
                className={
                  paymentMethod === 'ETH'
                    ? ethMintAmount >= ethLimitPerAddress
                      ? 'gradiantButton button buttonDisabled'
                      : 'gradiantButton button'
                    : wstMintAmount >= wstLimitPerAddress
                    ? 'gradiantButton button buttonDisabled'
                    : 'gradiantButton button'
                }
                onClick={() => {
                  buy();
                }}
              >
                {getMintButton()}
                {mintPending ? <LoadingOutlined style={{ marginLeft: 10 }} /> : null}
              </div>
              <div className={'balance'}>
                {languageFile.mint.balance}:{' '}
                <span className={'normalFont'}>
                  {numeral(paymentMethod === 'ETH' ? ethBalance : wscBalance).format('0.[00000')}
                </span>{' '}
                {paymentMethod}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className={'divider'} />
    </div>
  );
};

export default Mint;
