import React, { useContext } from 'react';
import './Roadmap.scss';
import { LanguageContext } from '../../contexts/LanguageContext';

const Roadmap: React.FC = () => {
  const languageFile = useContext(LanguageContext);
  return (
    <div className={'roadmap itemEntre'}>
      <div className={'subtitleWrapper'}>
        <img
          className={'numberImage rightImage horizontalImage'}
          src={`${process.env.PUBLIC_URL}/static/images/06.png`}
          alt="04"
        />
        <h1 className="subtitle themeYellow rightTitle">{languageFile.home.roadmap.title}</h1>
      </div>
      <div className="outerWrap">
        <div className="themeYellow init" style={{ marginBottom: 20 }}>
          2021
        </div>
        <div className="verticalGreenLineWrap" style={{ height: 50 }} />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left" />

            <div className="dotWrapper" />
            <div className="right">
              <div>
                <span className="themeYellow">2021 Q2 </span>
                {languageFile.home.roadmap.section1}
              </div>
            </div>
          </div>
        </div>
        <div className="verticalGreenLineWrap" />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left">
              <div>
                <span className="themeYellow">2021 Q3 </span>
                {languageFile.home.roadmap.section2}
              </div>
            </div>

            <div className="dotWrapper" />
            <div className="right" />
          </div>
        </div>
        <div className="verticalGreenLineWrap" />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left" />

            <div className="dotWrapper" />
            <div className="right">
              <div>
                <span className="themeYellow" style={{ marginRight: 5 }}>
                  Jan 2022{' '}
                </span>
                {languageFile.home.roadmap.section3}
              </div>
            </div>
          </div>
        </div>
        <div className="verticalGreenLineWrap" />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left">
              <div>
                <span className="themeRed">Feb 2022 </span>
                {languageFile.home.roadmap.section4}
              </div>
            </div>

            <div className="dotWrapper" />
            <div className="right" />
          </div>
        </div>
        <div className="verticalGreenLineWrap" />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left" />

            <div className="dotWrapper" />
            <div className="right">
              <div className={'doubleLineWrap'}>
                <span className="themeRed">2022 Q2</span>
                <div>
                  <p>{languageFile.home.roadmap.section4_2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="verticalGreenLineWrap" />*/}
        {/*<div className="contentWrap">*/}
        {/*  <div className="textWrap">*/}
        {/*    <div className="left">*/}
        {/*      <div>*/}
        {/*        <span className="themeRed">2022 Q2</span>Release WSA Theme Raffle*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <div className="dotWrapper" />*/}
        {/*    <div className="right" />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="verticalGreenLineWrap" />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left">
              <div>
                <span className="themeRed">2022 Q2</span>
                {languageFile.home.roadmap.section5_1}
                <br />
                {languageFile.home.roadmap.section5_2}
              </div>
            </div>

            <div className="dotWrapper" />
            <div className="right" />
          </div>
        </div>
        <div className="verticalGreenLineWrap" />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left" />

            <div className="dotWrapper" />
            <div className="right">
              <div>
                <span className="themeRed">2022 Q3</span>
                {languageFile.home.roadmap.section6}
              </div>
            </div>
          </div>
        </div>
        <div className="verticalGreenLineWrap" />
        <div className="contentWrap">
          <div className="textWrap">
            <div className="left">
              <div>
                <span className="themeRed">2022 Q4</span>
                {languageFile.home.roadmap.section7}
              </div>
            </div>

            <div className="dotWrapper" />
            <div className="right" />
          </div>
        </div>
        <div className="verticalGreenLineWrap" style={{ height: 50 }} />
        <div className="themeRed init" style={{ marginTop: 20 }}>
          2022
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
