import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import './styles.scss';
import { LanguageContext } from '../../contexts/LanguageContext';
import { MathComponent } from 'mathjax-react';

const Token: React.FC = () => {
  const languageFile = useContext(LanguageContext);

  return (
    <div className={'token mainContainer'}>
      <Row justify="space-between" align={'middle'} gutter={32}>
        <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
          <img src={`${process.env.PUBLIC_URL}/static/images/token/image1.png`} alt={'img'} />
        </Col>
        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
          <h2>{languageFile.token.introTitle}</h2>
          <p>{languageFile.token.intro1}</p>
          <p>{languageFile.token.intro2}</p>
        </Col>
      </Row>
      <div className={'ruleWrapper'}>
        <Row justify="space-between" align={'middle'} gutter={32}>
          <Col xs={24} sm={24} md={12}>
            <h2>{languageFile.token.ruleTitle}</h2>
            <ul>
              <li>
                <p>{languageFile.token.rule1}</p>
              </li>
              <li>
                <p>{languageFile.token.rule2}</p>
              </li>
              <li>
                <p>{languageFile.token.rule3}</p>
              </li>
              <li>
                <p>{languageFile.token.rule4}</p>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <img src={`${process.env.PUBLIC_URL}/static/images/token/image2.png`} alt={'img2'} />
          </Col>
        </Row>
      </div>

      <div className={'rewardWrap'}>
        <h2>{languageFile.token.rewardTitle}</h2>
        <p>{languageFile.token.reward1}</p>
        <p>{languageFile.token.reward2}</p>
        <br />
        <div className={'inlineMathjax normalText'}>
          {languageFile.token.reward3_1} <MathComponent tex={String.raw`wsa`} /> {languageFile.token.reward3_2}{' '}
          <MathComponent tex={String.raw`i`} />, let
          <MathComponent tex={String.raw`wst`} /> {languageFile.token.reward3_4} <MathComponent tex={String.raw`wsa`} />{' '}
          .
        </div>
        <div className={'normalText'}>
          {languageFile.token.let} <MathComponent tex={String.raw`T_m`} /> {languageFile.token.reward4}{' '}
          <MathComponent tex={String.raw`wsa`} />.
        </div>
        <div className={'normalText'}>
          {languageFile.token.let} <MathComponent tex={String.raw`T_c`} /> {languageFile.token.reward5}{' '}
          <MathComponent tex={String.raw`wst`} /> .
        </div>
        <div className={'normalText'}>
          {languageFile.token.let} <MathComponent tex={String.raw`T_i`} /> {languageFile.token.reward6}{' '}
          <MathComponent tex={String.raw`i`} />.
        </div>
        <br />
        <div className={'normalText'}>
          <MathComponent tex={String.raw`${languageFile.token.reward7}`} />
          {/*{languageFile.token.reward7}*/}
        </div>
        <br />
        <div className={'normalText'}>{languageFile.token.reward8}</div>
      </div>
      <img src={`${process.env.PUBLIC_URL}/static/images/token/token.png`} alt={'img2'} className={'token'} />

      <div className={'divider'} />
      
    </div>
  );
};

export default Token;
