import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Utils, numeral, multicall, PINATA_GATEWAY_URI } from '../../utils';
import { default as ADDRESSES } from '../../utils/ADDRESSES.json';
import { default as WSA } from '../../utils/abis/WSA.json';
import WsaCard from '../../components/WsaCard';
import axios from 'axios';
import './styles.scss';
import { LanguageContext } from '../../contexts/LanguageContext';

interface Props {
  address?: string;
}
interface ATTRIBUTE {
  [key: string]: string;
}

interface NFT {
  tokenId: number;
  image: string;
  name: string;
  description: string;
  tokenLogo: string;
  attribute: ATTRIBUTE;
}

const Wallet: React.FC<Props> = ({ address }) => {
  const [ownedNFTs, setOwnedNFTs] = useState<NFT[]>([]);
  const [ownedWsaIds, setOwnedWsaIds] = useState<number[]>([]);
  const [wscBalance, setWscBalance] = useState<number>(0);
  const [claimableWsc, setClaimableWsc] = useState<number>(0);
  const [claimPending, setClaimPending] = useState<boolean>(false);

  const languageFile = useContext(LanguageContext);

  useEffect(() => {
    async function getOwnedNFT() {
      if (Utils.wsa && address) {
        try {
          const amount = Number(await Utils.wsa.methods.balanceOf(address).call());
          // console.log(`>>>> amount: ${amount}`)
          const calls = [];
          for (let i = 0; i < amount; i++) {
            calls.push([Utils.wsa._address, 'tokenOfOwnerByIndex', [address, i]]);
          }
          const res = await multicall(WSA.abi, calls).catch((e) => {
            console.log(e);
          });

          const ids: number[] = [];
          res.forEach((id: any) => ids.push(Number(id[0].toString())));
          setOwnedWsaIds(ids);
          const tokenUriCalls = [];

          for (let i = 0; i < amount; i++) {
            tokenUriCalls.push([Utils.wsa._address, 'tokenURI', [ids[i]]]);
          }
          const tokenUriRes = await multicall(WSA.abi, tokenUriCalls).catch((e) => {
            console.log(e);
          });
          const tokenUris: string[] = [];
          tokenUriRes.forEach((uri: string) => tokenUris.push(uri[0].toString()));
          const nfts: NFT[] = [];

          for (let i = 0; i < ids.length; i++) {
            const tokenInfoLink = tokenUris[i].replace('ipfs://', PINATA_GATEWAY_URI);
            const res = await axios.get(tokenInfoLink);
            res.data.tokenId = ids[i];
            res.data.image = res.data.image.replace('ipfs://', PINATA_GATEWAY_URI);
            const attributesArray = res.data.attributes;
            const attributes: {
              [key: string]: string;
            } = {};
            attributesArray.map((att: ATTRIBUTE) => (attributes[att.trait_type] = att.value));
            res.data.attribute = attributes;
            nfts.push(res.data);
          }
          setOwnedNFTs(nfts);
        } catch (e) {
          console.log(e);
        }
      }
    }

    getOwnedNFT().then();
  }, [address]);

  useEffect(() => {
    let timer: any;
    async function getTokenData() {
      if (Utils.wsc && Utils.linearUnlock && address && ownedWsaIds.length > 0) {
        try {
          const myWscBalance = Utils.web3.utils.fromWei(
            await Utils.wsc.methods.balanceOf(address).call({ from: address }),
          );
          setWscBalance(myWscBalance);
          const myClaimableWsc = Number(
            Utils.web3.utils.fromWei(await Utils.linearUnlock.methods.earnedAll(ownedWsaIds).call({ from: address })),
          );
          // const myClaimableWsc = rewards.reduce((a: number, b: any) => a + Number(Utils.web3.utils.fromWei(b)), 0);
          setClaimableWsc(myClaimableWsc);
        } catch (e) {
          console.log(e);
        }
      }

      timer = setTimeout(() => {
        getTokenData().then();
      }, 15000);
    }
    getTokenData().then();

    return () => clearTimeout(timer);
  }, [address, ownedWsaIds]);

  function claimWsc() {
    if (Number(claimableWsc) === 0) {
      message.info(languageFile.message.noClaimableWst);
      return;
    }

    if (!Utils.linearUnlock || ownedWsaIds.length === 0) {
      return;
    }

    try {
      Utils.linearUnlock.methods
        .getReward(ownedWsaIds)
        .send({
          from: address,
        })
        .on('transactionHash', (hash: string) => {
          console.log(hash);
          setClaimPending(true);
          message.success(languageFile.message.transactionSent, 3);
        })
        .then((res: any) => {
          console.log(res);
          setClaimPending(false);
          message.success(languageFile.message.transactionCompleted, 3);
        })
        .catch((err: any) => {
          console.log(err);
          setClaimPending(false);
          message.error(err.message, 3);
        });
    } catch (e) {
      console.log(e);
      message.error(`Transaction failed with :${e}`, 3);
    }
  }

  return (
    <div className={'wallet mainContainer'}>
      <img className={'walletImg'} src={`${process.env.PUBLIC_URL}/static/images/wallet.png`} alt="wallet" />
      <div className={'wscWrapper'}>
        <div className={'subtitle'}>{languageFile.wallet.title}</div>
        <div className={'addressRow'}>
          <div className={'addressWrap'}>
            <div className={'key'}>WST Token Address:</div>
            <div className={'address'}>{ADDRESSES.wsc}</div>
          </div>

          <CopyToClipboard text={ADDRESSES.wsc} onCopy={() => message.success('Copied to Clipboard')}>
            <img className={'copy'} src={`${process.env.PUBLIC_URL}/static/images/copy.png`} alt="复制.png" />
          </CopyToClipboard>
        </div>
        <div className={'balanceRow'}>
          <div className={'key'}>{languageFile.wallet.wallet} WST: </div>
          <div className={'value'}>
            <span>{numeral(wscBalance).format('0,0.[0000]')}</span> WST
          </div>
        </div>
        <div className={'claimRow'}>
          <div className={'balanceRow'}>
            <div className={'key'}>{languageFile.wallet.accumulated} WST: </div>
            <div className={'value'}>
              <span>{numeral(claimableWsc).format('0,0.[0000]')}</span> WST
            </div>
          </div>
          <div
            className={'gradiantButton button'}
            onClick={() => {
              claimWsc();
            }}
          >
            {languageFile.wallet.claim} {claimPending ? <LoadingOutlined style={{ marginLeft: 10 }} /> : null}
          </div>
        </div>
      </div>
      <div className={'wsaWrapper'}>
        <div className={'subtitle'}>{languageFile.wallet.ownedCollections}</div>
        <div className={'ownedNFTs'}>
          {ownedNFTs.length === 0 ? (
            <WsaCard isPlaceholder={true} />
          ) : (
            ownedNFTs.map((nft, index) => <WsaCard key={`${nft.tokenLogo}${index}`} isPlaceholder={false} nft={nft} />)
          )}
        </div>
      </div>

      <div className={'divider'} />
    </div>
  );
};

export default Wallet;
