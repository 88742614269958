import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { message } from 'antd';
import Home from './views/Home';
import Mint from './views/Mint';
import Wallet from './views/Wallet';
import Token from './views/Token';
import Header from './components/Header/';
import Footer from './components/Footer/';
import { LanguageContext } from './contexts/LanguageContext';
import { default as defaultLanguageFile } from './assets/lang/en.json';
import { Utils, CURRENT_SUPPORT_CHAIN_ID } from './utils';
import ScrollToTop from './utils/ScrollToTop';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import './App.scss';

const App: React.FC = () => {
  const faqRef = useRef(null);
  // const [lang, setLang] = useState<string>('en');
  const [languageFile, setLanguageFile] = useState<any>(defaultLanguageFile);
  const [address, setAddress] = useState<string | undefined>();
  const [chainId, setChainId] = useState<number>(-1);
  useEffect(() => {
    if (window.location.href.includes('wallstreet.art/mint')) {
      window.location.replace('https://wallstreet.art/#/mint');
    }
    readWeb3Instance().then();
    // Utils.setWeb3(false);
    getLang();
    message.config({
      duration: 2,
      maxCount: 3,
    });
  });

  //change language selection and set it to sessionStorage
  // function changeLang(value: string) {
  //   sessionStorage.lang = value;
  //   getLang();
  // }

  //read language selection from sessionStorage
  function getLang() {
    let currentLang = sessionStorage.getItem('lang') || 'en';

    sessionStorage.lang = currentLang;
    // setLang(currentLang);
    setLanguageFile(require('./assets/lang/' + currentLang + '.json'));
  }

  async function readWeb3Instance(): Promise<any> {
    const provider: any = await detectEthereumProvider();

    if (provider) {
      const newWeb3 = new Web3(provider);

      newWeb3.eth.net
        .getId()
        .then((res: any) => {
          setChainId(res);
          if (res === CURRENT_SUPPORT_CHAIN_ID) {
            Utils.setWeb3(provider);
            provider
              .request({ method: 'eth_requestAccounts' })
              .then((accounts: string[]) => {
                handleAccountsChanged(accounts).then();
              })
              .catch((err: any) => {
                Utils.setWeb3(false);
                console.error(err);
              });

            provider.on('accountsChanged', () => {
              window.location.reload();
            });
          } else {
            Utils.setWeb3(false);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });

      provider.on('chainChanged', () => {
        window.location.reload();
      });
    } else {
      console.error('wait for MetaMask');
      Utils.setWeb3(false);
    }
  }

  async function handleAccountsChanged(accounts: string[]) {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== address) {
      setAddress(accounts[0]);
    }
  }

  return (
    <LanguageContext.Provider value={languageFile}>
      <div className="App">
        <Header address={address} faqRef={faqRef} readWeb3Instance={readWeb3Instance} chainId={chainId} />
        <Fragment>
          <ScrollToTop />

          <Switch>
            <Route path="/" exact>
              <Home faqRef={faqRef} />
            </Route>
            <Route path="/token" exact>
              <Token />
            </Route>
            <Route path="/mint" exact>
              <Mint address={address} readWeb3Instance={readWeb3Instance} chainId={chainId} />
            </Route>
            <Route path="/wallet" exact>
              <Wallet address={address} />
            </Route>
            {/*<Route path="/faq" exact>*/}
            {/*  <Home faqRef={faqRef} />*/}
            {/*</Route>*/}
            <Redirect to="/" />
          </Switch>
        </Fragment>
        <Footer />
      </div>
    </LanguageContext.Provider>
  );
};

export default App;
