import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Roadmap from './Roadmap';
import FAQ from './FAQ';
import { Row, Col } from 'antd';
import { LanguageContext } from '../../contexts/LanguageContext';
import './styles.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { default as ADDRESSES } from '../../utils/ADDRESSES.json';
import { message } from 'antd';
import { reduceAddress } from '../../utils/index';

const Home: React.FC<any> = ({ faqRef }) => {
  const languageFile = useContext(LanguageContext);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 765);

  useEffect(() => {
    const handleResize = () => {
      if ( window.innerWidth > 765)
        setIsMobile(false)
      else
        setIsMobile(true);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div className={'home mainContainer'}>
      <div className={'banner'}>
        <Row justify="space-between" align={'middle'} gutter={{ xs: 0, sm: 0, md: 32 }}>
          <Col xs={24} sm={24} md={12}>
            <div>
              {/* <h1>
                <span className="themeRed">Wall Street Art</span>
                <span className="themeYellow">NFT</span>
              </h1> */}
              <h1>{languageFile.home.welcomeMessage}</h1>
              <h3>{languageFile.home.slogan}</h3>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div className="rightWrapper">
              <p>{languageFile.home.intro}</p>
            </div>
          </Col>
        </Row>



        <Row justify="space-between" align={'middle'} gutter={{ xs: 0, sm: 0, md: 32 }}>
          <Col xs={{span: 24, order: 2}} sm={{span: 12, order: 1}} md={{span: 12, order: 1}}>
            <Row justify="center" gutter={{ xs: 0, sm: 0, md: 32 }}>
              <Col xs={12} sm={24} md={24}>
                <Link to={'/mint'}>
                  <div
                    className={'gradiantButton button'}
                    // onClick={() => {
                    //   message.info('Launch Soon');
                    // }}
                  >
                    {languageFile.home.mint}
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={24} md={24}>
                <div className={'socialMediaWrap'}>
                  <a href={'https://t.me/wallstreetart'} target="_blank" rel="noopener noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/static/images/telegram.png`} alt="telegram" />
                  </a>
                  <a href={'https://discord.gg/3WqsXnq79E'} target="_blank" rel="noopener noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/static/images/discord.png`} alt="discord" />
                  </a>
                  <a href={'https://twitter.com/WSA_NFT'} target="_blank" rel="noopener noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/static/images/twitter.png`} alt="twitter" />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col style={{borderTop: "solid 1px lightgray", marginTop:"5px"}} xs={{span: 24, order: 1}} sm={{span: 12, order: 2}} md={{span: 12, order: 2}}>
            <div className={'addressRow'}>
              <div className={'addressWrap'}>
                <div className={'key'}>WSA NFT Address:</div>
                <div className={'address'}>
                  <p>{isMobile?reduceAddress(ADDRESSES.wsa):ADDRESSES.wsa}</p>
                  <CopyToClipboard text={ADDRESSES.wsa} onCopy={() => message.success('Copied NFT Address Clipboard')}>
                    <img className={'copy'} src={`${process.env.PUBLIC_URL}/static/images/copy.png`} alt="复制.png" />
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className={'addressRow'}>
              <div className={'addressWrap'}>
                <div className={'key'}>WST Token Address:</div>
                <div className={'address'}>
                  <p>{isMobile? reduceAddress(ADDRESSES.wsc):ADDRESSES.wsc}</p>
                  <CopyToClipboard text={ADDRESSES.wsc} onCopy={() => message.success('Copied Token Address Clipboard')}>
                    <img className={'copy'} src={`${process.env.PUBLIC_URL}/static/images/copy.png`} alt="复制.png" />
                  </CopyToClipboard>
                </div>
              </div>


            </div>
          </Col>
        </Row>
      </div>
      <div className='openSeaWrapper'>
        <h1>Buy a WSA</h1>
        <p>To get your WSA, <br/> check out the collection on OpenSea</p>
        {/* <div
          className={'openSeaButtonWrapper'}
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/images/openSea.png`}
            alt="OpenSea"
          />
          <a href={"https://opensea.io/collection/wsa"} target={'_blank'} rel="noreferrer">
            <div
              className={'underLinedButton button'}
              // onClick={() => {
              //   message.info('Launch Soon');
              // }}
            >
              Buy It on OpenSea
            </div>
          </a>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/rightArrow.png`}
            alt="rightArrow"
            className={'arrowImg'}
          />
        </div> */}
        <a href={"https://opensea.io/collection/wsa"} target={'_blank'} rel="noreferrer">
          <div className='openSeaButtonWrapper'>
            <p>Buy a WSA On Opensea</p>
          </div>
        </a>
      </div>

      <div className={'introductionWrapper'}>
        <Row justify="space-between" align={'bottom'} gutter={{ xs: 0, sm: 0, md: 32 }} className="itemEntre">
          <Col xs={24} sm={24} md={11}>
            <div className={'subtitleWrapper'}>
              <img
                className={'numberImage leftImage'}
                src={`${process.env.PUBLIC_URL}/static/images/01.png`}
                alt="01"
              />
              <div className={'subtitle themeRed leftTitle'}>{languageFile.home.section1Title}</div>
            </div>

            <p className='leftP'>{languageFile.home.section1Intro}</p>
            <div className={'buttons'}>
              <div
                className={'arrowButtonWrap'}
              >
                <a href={`${process.env.PUBLIC_URL}/static/guide.pdf`} target={'_blank'} rel="noreferrer">
                  <div
                    className={'underLinedButton button'}
                    // onClick={() => {
                    //   message.info('Launch Soon');
                    // }}
                  >
                    {languageFile.home.howToJoin}
                  </div>
                </a>
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/rightArrow.png`}
                  alt="rightArrow"
                  className={'rightArrow'}
                />
              </div>

              {/*<div*/}
              {/*  className={'gradiantButton button'}*/}
              {/*  onClick={() => {*/}
              {/*    message.info('Launch Soon');*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Join Now*/}
              {/*</div>*/}
              {/*</Link>*/}
              {/*<a href={`${process.env.PUBLIC_URL}/static/guide.pdf`} target={'_blank'} rel="noreferrer">*/}
              {/*  <div className={'arrowButtonWrap'}>*/}
              {/*    <div*/}
              {/*      className={'underLinedButton button'}*/}
              {/*      onClick={() => {*/}
              {/*        message.info('Launch Soon');*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      How to join*/}
              {/*    </div>*/}
              {/*    <img*/}
              {/*      src={`${process.env.PUBLIC_URL}/static/images/rightArrow.png`}*/}
              {/*      alt="rightArrow"*/}
              {/*      className={'rightArrow'}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</a>*/}
            </div>
          </Col>
          <Col xs={24} sm={24} md={11}>
            <img
              src={`${process.env.PUBLIC_URL}/static/images/homeIllustration1.png`}
              alt="example"
              className={'illustrationImg'}
            />
          </Col>
        </Row>
        <Row justify="space-between" align={'middle'} gutter={{ xs: 0, sm: 0, md: 32 }} className="itemEntre">
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 11, order: 1 }}>
            <img
              src={`${process.env.PUBLIC_URL}/static/images/homeIllustration2.png`}
              alt="example"
              className={'algorithmImage illustrationImg'}
            />
          </Col>
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 11, order: 2 }}>
            <div className={'subtitleWrapper'}>
              <img
                className={'numberImage rightImage'}
                src={`${process.env.PUBLIC_URL}/static/images/02.png`}
                alt="02"
              />
              <div className={'subtitle rightTitle themeYellow'}>{languageFile.home.section2Title}</div>
            </div>
            <p className='rightP'>{languageFile.home.section2Intro}</p>
          </Col>
        </Row>

        <Row
          justify="space-between"
          align={'middle'}
          gutter={{ xs: 0, sm: 0, md: 32 }}
          className="itemEntre"
        >
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 13, order: 1 }}>
            <p className='rightP'>{languageFile.home.section3Intro}</p>
          </Col>
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 11, order: 2 }}>
            <div className={'subtitleWrapper'}>
              <img
                className={'numberImage rightImage'}
                src={`${process.env.PUBLIC_URL}/static/images/03.png`}
                alt="03"
              />
              <div className={'subtitle themeRed aestheticTitle'}>{languageFile.home.section3Title}</div>
            </div>
          </Col>
        </Row>

        <Row
          justify="space-between"
          align={'middle'}
          gutter={{ xs: 0, sm: 0, md: 32 }}
          className="itemEntre"
        >
          <Col xs={24} sm={24} md={13}>
            <div className={'subtitleWrapper'}>
              <img className={'numberImage'} src={`${process.env.PUBLIC_URL}/static/images/04.png`} alt="04" />
              <div className={'subtitle themeRed aestheticTitle'}>{languageFile.home.section4Title}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={11}>
            <p className='leftP'>{languageFile.home.section4Intro}</p>
          </Col>
        </Row>

        <Row
          justify="space-between"
          align={'middle'}
          gutter={{ xs: 0, sm: 0, md: 32 }}
          className="itemEntre"
        >
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 13, order: 1 }}>
            <p className='rightP'>{languageFile.home.section6Intro}</p>
          </Col>
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 11, order: 2 }}>
            <div className={'subtitleWrapper'}>
              <img
                className={'numberImage rightImage'}
                src={`${process.env.PUBLIC_URL}/static/images/05.png`}
                alt="03"
              />
              <div className={'subtitle themeRed aestheticTitle tokenTitle'}>{languageFile.home.section6Title}</div>
            </div>
          </Col>
        </Row>

        <Roadmap />

        <Row justify="space-between" align={'bottom'} gutter={{ xs: 0, sm: 0, md: 32 }} className="itemEntre">
          <Col xs={24} sm={24} md={13}>
            <div className={'subtitleWrapper'}>
              <img
                className={'numberImage leftImage'}
                src={`${process.env.PUBLIC_URL}/static/images/07.png`}
                alt="01"
              />
              <div className={'subtitle themeRed leftTitle'}>{languageFile.home.section5Title}</div>
            </div>

            <p className='leftP'>{languageFile.home.section5Intro}</p>
          </Col>
          <Col xs={24} sm={24} md={11}>
            <img
              src={`${process.env.PUBLIC_URL}/static/images/homeIllustration3.png`}
              alt="example"
              className={'illustrationImg'}
            />
          </Col>
        </Row>
        <Row justify="space-between" align={'bottom'} gutter={{ xs: 0, sm: 0, md: 32 }} className="itemEntre">
          <Col xs={{span: 24, order: 2}} sm={{span:24, order:2}} md={{span: 11, order: 1}}>
            <img
              src={`${process.env.PUBLIC_URL}/static/images/WSAFrame.png`}
              alt="example"
              className={'algorithmImage illustrationImg'}
            />
          </Col>
          <Col xs={{span: 24, order: 1}} sm={{span:24, order:1}} md={{span: 13, order: 2}}>
            <div className={'subtitleWrapper frameWrapper'}>
              <img
                className={'rightImage frameNumber'}
                src={`${process.env.PUBLIC_URL}/static/images/08.png`}
                alt="02"
              />
              <div className={'frameTitle subtitle rightTitle themeYellow'}>{languageFile.home.section8Title}</div>
            </div>
            <p className='rightP frameBody'>{languageFile.home.section8Intro}</p>
            <div
                className={'gradiantButton button rightButton'}
                onClick={() => {
                  window.open("https://www.metasill.io/", "_blank");
                }}
              >
                Learn More
              </div>
          </Col>
        </Row>
        <FAQ faqRef={faqRef} />
      </div>

      <div className={'divider'} />
    </div>
  );
};

export default Home;
