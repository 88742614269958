import { Interface } from '@ethersproject/abi';
import Web3 from 'web3';
import { default as ADDRESSES } from './ADDRESSES.json';
import { default as WSA } from './abis/WSA.json';
import { default as Multicall } from './abis/Multicall.json';
import { default as WsaSeller } from './abis/WsaSeller.json';
import { default as IERC20 } from './abis/IERC20.json';
import { default as LinearUnlock } from './abis/LinearUnlock.json';

const WSA_RPC = 'https://mainnet.infura.io/v3/dd904eb8bbff4878af191c13a75efe4c';
export const CURRENT_SUPPORT_CHAIN_ID = 1;
export const PINATA_GATEWAY_URI = 'https://wallstreetart.mypinata.cloud/ipfs/';
export const Utils = {
  web3: false as any,
  wsa: false as any,
  wsc: false as any,
  seller1: false as any,
  linearUnlock: false as any,
  multicall: false as any,

  async setWeb3(provider: any) {
    if (!provider) {
      this.web3 = new Web3(WSA_RPC);
    } else {
      this.web3 = new Web3(provider);
    }

    if (this.web3.utils.isAddress(ADDRESSES.wsa)) {
      this.wsa = new this.web3.eth.Contract(WSA.abi, ADDRESSES.wsa);
    }
    if (this.web3.utils.isAddress(ADDRESSES.multicall)) {
      this.multicall = new this.web3.eth.Contract(Multicall.abi, ADDRESSES.multicall);
    }

    if (this.web3.utils.isAddress(ADDRESSES.wsc)) {
      this.wsc = new this.web3.eth.Contract(IERC20.abi, ADDRESSES.wsc);
    }
    if (this.web3.utils.isAddress(ADDRESSES.seller1)) {
      this.seller1 = new this.web3.eth.Contract(WsaSeller.abi, ADDRESSES.seller1);
    }
    if (this.web3.utils.isAddress(ADDRESSES.linearUnlock)) {
      this.linearUnlock = new this.web3.eth.Contract(LinearUnlock.abi, ADDRESSES.linearUnlock);
    }
  },
};

export function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time * 1000));
}

export const RELEASE_TIME = 'Mon Sep 7 2021 12:00:00 UTC';

export function checkTime(timeString = RELEASE_TIME) {
  let now = new Date().getTime();
  let deadline = new Date(timeString).getTime();
  return now >= deadline;
}

export const numeral = require('numeral');

export const reduceAddress = (addr: string | undefined) => {
  if (addr) {
    return addr.substring(0, 6) + '...' + addr.substring(addr.length - 4, addr.length);
  }
  return 'Connect Wallet';
};

const PAGE = 100;
export const multicall = async (abi: any, calls: any) => {
  const multi = Utils.multicall;
  const itf = new Interface(abi);
  let res: any = [];

  if (calls.length > PAGE) {
    let i = 0;
    while (i < calls.length / PAGE) {
      const newCalls = calls.slice(i * PAGE, PAGE * (i + 1));
      const calldata = newCalls.map((call: any) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])]);
      const { returnData } = await multi.methods.aggregate(calldata).call();
      i++;
      res = res.concat(
        returnData.map((call: any, index: number) => itf.decodeFunctionResult(newCalls[index][1], call)),
      );
    }
  } else {
    const calldata = calls.map((call: any) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])]);
    const { returnData } = await multi.methods.aggregate(calldata).call();
    res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i][1], call));
  }
  return res;
};

export const date2CountdownString = (date: any) => {
  let n = date.toUTCString();
  let timeString = n.substring(0, 3) + n.substring(7, 12) + n.substring(5, 7) + n.substring(11, n.length - 4) + ' UTC';
  return timeString;
};
