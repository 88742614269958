import * as React from 'react';
import './styles.scss';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
const Footer: React.FC = () => {
  const languageFile = useContext(LanguageContext);
  return (
    <div className={'footer'}>
      <h3>{languageFile.footer.joinOurCommunity}</h3>
      <div className={'socialMediaWrap'}>
        <a href={'https://t.me/wallstreetart'} target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/static/images/telegram.png`} alt="telegram" />
        </a>

        <a href={'https://discord.gg/3WqsXnq79E'} target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/static/images/discord.png`} alt="discord" />
        </a>
        <a href={'https://twitter.com/WSA_NFT'} target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/static/images/twitter.png`} alt="twitter" />
        </a>
        <a href={'https://opensea.io/collection/wsa'} target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/static/images/openSea.png`} alt="twitter" />
        </a>
      </div>
      <p>© 2022 WallStreetArts</p>
    </div>
  );
};

export default Footer;
